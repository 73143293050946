import React, {useEffect, useState} from 'react';
import '../css/home.css';
import logo from '../images/Logo-Eaggle-fond-outline.png';

export default function NotFound () {

      return (
          <div className="w-100 vh-100 bg-primary position-relative">
            <div className="card w-50 position-absolute top-50 start-50 translate-middle">
              <div className="card-body text-center">
                <img className="logoEaggle d-block mb-3" style={{width: 200, margin: '0 auto'}} src={logo} alt="logo of Eaggle company" aria-hidden="true" />
                La page demandée n'existe pas
              </div>
            </div>
          </div>
      );

};
