import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from './home';
import Mandant from './mandant';
import Mandataire from './mandataire';
import ProcurationValidation from './procuration/validation';
import ProcurationCancel from './procuration/cancel';
import ProcurationVote from './procuration/vote';
import NotFound from './404';

const Webpages = () => {
    return(
            <Routes>
                <Route exact path="/:electionSlug" element= {<Home />} />
                <Route path = "/:electionSlug/mandant" element = {<Mandant />} />
                <Route path = "/:electionSlug/mandataire" element = {<Mandataire />} />
                <Route path = "/procuration/validate/:token" element = {<ProcurationValidation />} />
                <Route path = "/procuration/cancel/:token" element = {<ProcurationCancel />} />
                <Route path = "/procuration/vote/:token" element = {<ProcurationVote />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
    );
};
export default Webpages;
