import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './css/themeBootstrap.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const mediumBlue = '#104a97';
const darkBlue = '#03417d';
const lightblue = '#5C82BE';

const theme = createTheme({
  palette: {
    'dark-blue': {
      main: darkBlue
    },
    'medium-blue': {
      light: '#aec1df',
      main: mediumBlue
    },
    'light-blue': {
      main: lightblue
    },
    'accent-color': {
      main: '#DC1B20'
    },
    'medium-red': {
      main: '#d32f2f'
    }
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
