import React, {useEffect, useState} from 'react';
import InscriptionForm from '../components/inscriptionForm';
//import "../css/forms.css";
import logo from '../images/Logo-Eaggle-fond-outline.png';
import axios from 'axios';
import Loader from "react-loader-spinner";
import { Link, useParams, useLocation, Navigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Navbar from 'react-bootstrap/Navbar';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Transition(props) {
  return <Slide {...props} direction="left" />;
}

export default function Mandant () {
  const location = useLocation();
  const indexSubstring = location.pathname.indexOf('/mandant');
  const homepagePath = location.pathname.split('').slice(0, indexSubstring).join('');


    let [user, setUser] = useState({
        familyName:"",
        preferredName:"",
        firstName:"",
        birthDate:"",
        gender:"",
        homeAddress:"",
        zipCode:"",
        city:"",
        email:"",
        phoneNumber:"",
        voteCity:"",
        numElecteur:""
    });
    let [isLoading, setIsLoading] = useState(true);
    let [notFound, setNotFound] = useState(false);
    let [procurationTour, setProcurationTour] = useState();
    let [legalAgreement, setLegalAgreement] = useState(false);
    let [contactEaggle, setContactEaggle] = useState(false);
    let [formErrors, setFormErrors] = useState({});
    let [loadingDataIntoDatabase, setLoadingDataIntoDatabase]= useState(false);
    let [inscriptionStatus, setInscriptionStatus] = useState("none");
    let [election, setElection] = useState({});
    let [procurationOptions, setProcurationOptions] = useState({});
    let [listCommunes, setListCommunes] = useState(null);
    let [snackbar, setSnackbar] = useState({
      open: false,
      message: "",
      variant: 'success',
    })
    let params = useParams();

    const { open, message, variant } = snackbar;

    const openSnackbar = (newState) => {
      setSnackbar({open: true, ...newState });
    }

    const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbar({...snackbar, open: false});
    }

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_API_ROOT}procuration/public/${params.electionSlug}`)
        .then(function (response) {
          if (response.data) {
            setElection({id: response.data.data.id});
            setProcurationOptions(JSON.parse(response.data.data.procurationOptions));
            setListCommunes(response.data.data.communes);
            setIsLoading(false);
          } else {
            setNotFound(true);
          }
        })
        .catch(function (error) {
          setNotFound(true);
        })
    }, []);


    ////// Functions to collect data onChange
    function handleInput(event){
        setProcurationTour(event.target.value);
    }

    function handleInputCheckbox(event){
        if (event.target.name==="legalAgreement"){
          let newLegalAgreement= !legalAgreement;
          setLegalAgreement(newLegalAgreement);

        }
        if (event.target.name==="contactEaggle"){
            let newContactStatus= !contactEaggle;
            setContactEaggle(newContactStatus);
        }
    }


    ////// Function to call back the user data through the props sended through <Inscription Form /> below
    function handleCallBack(childData){
        setUser(childData);
    }

    ////// Functions to validate each data entry
    function stringContainsNumber(str){
        return (/\d+/g).test(str);
        }

    function isEmailValid (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      }


    function checkIfStringHasSpecialChar(string, caseTest){
        let spChars = null;
        if (caseTest=== "homeAddress"){
            spChars = /[!@#$%^&*()_+\=\[\]{};:\\|.<>\/?]+/;
        } else {
            spChars = /[!@#$%^&*()_+\=\[\]{};:"\\|.<>\/?]+/;
        }
        return spChars.test(string);
    }

    ////// Function de validation du formulaire et de chacune de ses entrées
    function validateForm(event) {
        event.preventDefault();
        let newErrors = {};

        // Variables check for names (familyName, preferredName, firstName, city, voteCity)
        let names = [{name:"familyName", data: user.familyName}, {name:"preferredName", data: user.preferredName}, {name:"firstName", data: user.firstName}, {name:"city", data: user.city}, {name:"voteCity", data: user.voteCity}];
        let filterResultNames= names.filter(name => typeof name.data!=='string' || name.data.length <= 0 || stringContainsNumber(name.data)===true || checkIfStringHasSpecialChar(name.data, "general")===true)
        // La fonction filter renvoie un tableau avec les éléments erronés suivants les critères fournis
        filterResultNames.forEach(element=>{
            newErrors[element.name]=true;
        }) // On fait une boucle dans chaque élement du tableau pour récupérer son nom et lui associer une valeur d'erreur true --> ensuite mise à jour de notre variable formErrors qui collecte toutes les erreurs

        // Variables check for numbers (zipCode, phoneNumber)
        let numbers = [{name:"zipCode", data: user.zipCode}, {name:"phoneNumber", data: user.phoneNumber}];
        let filterResultNumbers= numbers.filter(number => number.data.length <= 4 || isNaN(number.data)===true)
        filterResultNumbers.forEach(element=>{
            newErrors[element.name]=true;
        })

        // Variables check for homeAddress
        if (typeof user.homeAddress!=='string' || user.homeAddress.length <= 0 || checkIfStringHasSpecialChar(user.homeAddress, "homeAddress")===true){
            newErrors.homeAddress=true;
        }

        // Variables check for the email
        if (user.email.length <= 4 || isEmailValid(user.email)===false){
            newErrors.email=true;
        }

        // Variables check for birthDate
        let now_year = new Date().getFullYear();
        let now_month = new Date().getMonth()+1;
        let user_birthYear = parseInt(user.birthDate.substring(0,4));
        let user_birthMonth = parseInt(user.birthDate.substring(5,7));
        let user_age = Math.round(((now_year*12+now_month)-(user_birthYear*12+user_birthMonth))/12) // Sélection sur l'âge OK à partir de 17 ans et demi pour éviter de rejeter des votants tout juste majeurs le jour de l'élection --> contrôle ultérieur de la présence sur les listes électorales
        if (typeof user.birthDate!=='string' || user.birthDate.length !== 10 || checkIfStringHasSpecialChar(user.birthDate, "general")===true || user_age <= 18 || user_age >=110 ){
            newErrors.birthDate=true;
        }

        // Variables check for Boolean values (legalAgreement, contactEaggle)
        let boolean = [{name:"legalAgreement", data: legalAgreement}, {name:"contactEaggle", data: contactEaggle}];
        let filterResultBoolean= boolean.filter(boolean => typeof boolean.data!=='boolean' || boolean.data.length <= 3)
        filterResultBoolean.forEach(element=>{
            newErrors[element.name]=true;
        })
        if (legalAgreement === false){
            newErrors["legalAgreement"]=true;
        }

        // Variables check for values with mandatory set of responses (gender, procurationTour)
        if (user.gender!=="femme" && user.gender!=="homme"){
            newErrors.gender=true;
        }
        let validProcurationTourEntries = ["firstTour", "secondTour", "twoTours"];
        if (validProcurationTourEntries.includes(procurationTour)=== false) {
            newErrors.procurationTour=true;
        }
        setFormErrors(newErrors); // Recording of all errors to indicate inputs to correct to the user

        if (Object.keys(newErrors).length === 0){
            handleSubmit()
        } else { // if there is no error, submit the form
            openSnackbar({message: "Veuillez verifier les champs du formulaire", variant: "warning"});
        }
    }

    ////// Reception de la réponse de l'API
    function handleResponse(response){
      setLoadingDataIntoDatabase(false);
      if (response.data.success) {
        setInscriptionStatus("success");
      } else {
        openSnackbar({message: "Une erreur est survenue", variant: "error"});
      }
    }


    ////// Soumission du formulaire à l'API
    function handleSubmit(){
        if (legalAgreement){
            legalAgreement=1
        } else {
            legalAgreement=0
        }
        if (contactEaggle){
            contactEaggle=1
        } else {
            contactEaggle=0
        }

        setLoadingDataIntoDatabase(true);
        openSnackbar({message: "Merci de patienter", variant: "info"});

        axios.post(`${process.env.REACT_APP_API_ROOT}procuration/mandant`, {
            familyName: user.familyName,
            preferredName: user.preferredName,
            firstName: user.firstName,
            birthDate: user.birthDate,
            gender: user.gender,
            homeAddress: user.homeAddress,
            zipCode: user.zipCode,
            city: user.city,
            email: user.email,
            phoneNumber: user.phoneNumber,
            voteCity: user.voteCity,
            numElecteur: user.numElecteur,
            procurationTour: procurationTour,
            legalAgreement: legalAgreement,
            contactEaggle: contactEaggle,
            electionId: election.id
        }).then(handleResponse);

    }

    if (isLoading) {
      return ( <>
        {notFound && <Navigate to='/404' />}
        <div className="position-absolute top-50 start-50 translate-middle">
          <CircularProgress />
        </div>
      </>);
    } else {
      if (inscriptionStatus === "success"){
          return (
              <Container maxWidth={false} className="px-0">
                <header style={{
                  backgroundColor: procurationOptions.backgroundColor || '#104a97',
                  backgroundImage: `url(${process.env.REACT_APP_API_ROOT}public/header/${procurationOptions.header || 'default.jpg'}`
                }}></header>
                <Container maxWidth="md" className="row align-items-center h-100  my-3">
                    <div className="text-center">
                        <h2 className="text-primary">Merci de votre soutien ! 🗳 </h2>
                        <p className="fw-bold text-secondary">Votre demande de procuration a bien été enregistrée</p>
                        <p>Un email de confirmation vous a été envoyé. Les prochaines étapes y sont présentées en détails.</p>
                        <Link to={`/${params.electionSlug}`} className="btn btn-outline-primary">Retour à la page d'accueil</Link>
                    </div>
                </Container>
            </Container>)

      } else {

            return (
              <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 1 }}
                    open={loadingDataIntoDatabase}
                  >
                      <Loader
                      id="loader"
                      type="MutatingDots"
                      color="#DC1B20"
                      secondaryColor="#104a97"
                      height={100}
                      width={100}
                      timeout={10000000} //  dispear after...ms
                      />
                </Backdrop>
                <Container maxWidth={false} className="px-0">
                  <header style={{
                    backgroundColor: procurationOptions.backgroundColor || '#104a97',
                    backgroundImage: `url(${process.env.REACT_APP_API_ROOT}public/header/${procurationOptions.header || 'default.jpg'}`
                  }}></header>
                   <h2 className="text-center fw-bold my-3" style={{ color: procurationOptions.fontColor || "#104a97"}}>Je veux donner procuration</h2>
                   {listCommunes && <>
                      <form onSubmit={validateForm} noValidate>
                        <InscriptionForm parentCallBack={handleCallBack} formErrors={formErrors} communes={listCommunes} fontColor={procurationOptions.fontColor || "#104a97"} />

                        <Container maxWidth="sm" className="mt-3">
                          <p className="">Je souhaite donner ma procuration pour... *</p>
                          <Box
                            sx={{
                              width: "100%",
                              border: '1px solid',
                              borderColor: formErrors.procurationTour ? '#d32f2f' : 'medium-blue.main',
                              borderRadius: 3,
                              p: 3,
                              boxShadow: 4,
                              backgroundColor: '#eaecf4',
                              mb: 2
                            }}
                          >
                            <FormControl>
                              <RadioGroup
                                name="procurationTour"
                                onChange={handleInput}
                              >
                                <FormControlLabel value="firstTour" control={<Radio color="medium-blue" size="small" />} label="le 1er tour uniquement"/>
                                <FormControlLabel value="secondTour" control={<Radio color="medium-blue" size="small" />} label="le 2nd tour uniquement"/>
                                <FormControlLabel value="twoTours" control={<Radio color="medium-blue" size="small" />} label="les deux tours"/>
                              </RadioGroup>
                            </FormControl>
                          </Box>

                          <FormGroup
                            sx={{
                              color: formErrors.legalAgreement ? '#d32f2f' : '#212529',
                            }}
                          >
                            <FormControlLabel control={
                              <Checkbox
                                sx={{
                                  color: formErrors.legalAgreement ? '#d32f2f' : '#104a97',
                                  '&.Mui-checked': {
                                    color: formErrors.legalAgreement ? '#d32f2f' : '#104a97',
                                  }
                                }}
                                onChange={handleInputCheckbox}
                                name="legalAgreement"
                                id="legalAgreement"
                              />
                            } label={<>J'accepte les <a href="/" target="_blank" rel="no-referrer">conditions d'utilisation</a> de mes données personnelles dans le cadre de cette demande de procuration</>} />
                          </FormGroup>
                          <FormGroup>
                            <FormControlLabel control={
                              <Checkbox
                                color="medium-blue"
                                onChange={handleInputCheckbox}
                                name="contactEaggle"
                                id="contactEaggle"
                              />
                            } label="Je souhaite être re-contacté(e) par une personne de l'équipe de campagne" />
                          </FormGroup>

                          <div className="d-flex justify-content-between my-4">
                            <Link to={`/${params.electionSlug}`} className="text-primary">Retour</Link>
                            <button className="btn btn-primary" type="submit">Soumettre ma demande de procuration</button>
                          </div>
                        </Container>
                      </form>
                    </>}
                </Container>
                <footer style={{
                  backgroundColor: procurationOptions.footerColor || '#104a97',
                  color: procurationOptions.fontColor || '#ffffff'
                }}>
                    <ul className="legal" style={{textAlign: 'right'}}>
                        <li>Vous rencontrez des difficultés avec ce formulaire ? Contactez-nous à { procurationOptions.replyto }</li>
                        <li>Mentions légales</li>
                        <li>Protection des données</li>
                    </ul>
                </footer>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={open}
                  onClose={closeSnackbar}
                  autoHideDuration={3000}
                  TransitionComponent={Transition}
                >
                  <Alert severity={variant} sx={{ width: '100%' }}>
                    {message}
                  </Alert>
                </Snackbar>
              </>
            );

      }
    }
};
