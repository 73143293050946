import React, {useEffect, useState} from 'react';
//import "../css/forms.css";
import logo from '../../images/Logo-Eaggle-fond-outline.png';
import axios from 'axios';
import Loader from "react-loader-spinner";
import { Link, useParams, useLocation, Navigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

export default function ProcurationVote () {
  const location = useLocation();
  const indexSubstring = location.pathname.indexOf('/mandant');
  const homepagePath = location.pathname.split('').slice(0, indexSubstring).join('');

    let [isLoading, setIsLoading] = useState(true);
    let [notFound, setNotFound] = useState(false);
    let [procurationOptions, setProcurationOptions] = useState({});
    let [status, setStatus] = useState("pending")
    let [tour, setTour] = useState(0);
    let params = useParams();
    const tours = { 1: "1er tour", 2: "2nd tour" };

    function handleValide() {

      axios.post(`${process.env.REACT_APP_API_ROOT}procuration/vote`, {token: params.token})
      .then(function(response){
          console.log(response)
          setStatus(response.data.status);
      });
    }

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_API_ROOT}procuration/vote/${params.token}`)
        .then(function (response) {
          if (response.data) {
            setProcurationOptions(JSON.parse(response.data.data.procurationOptions));
            setTour(response.data.data.tour);
            if (response.data.status === "success") {
              setStatus("success");
            }
            setIsLoading(false);
          } else {
            //setNotFound(true);
          }
        })
        .catch(function (error) {
          //setNotFound(true);
        })
    }, []);

    if (isLoading) {
      return ( <>
        {notFound && <Navigate to='/404' />}
        <div className="position-absolute top-50 start-50 translate-middle">
          <CircularProgress />
        </div>
      </>);
    } else {
        return (
            <Container maxWidth={false} className="px-0">
              <header style={{
                backgroundColor: procurationOptions.backgroundColor || '#104a97',
                backgroundImage: `url(${process.env.REACT_APP_API_ROOT}public/header/${procurationOptions.header || 'default.jpg'}`
              }}></header>
              <Container maxWidth="md" className="row align-items-center h-100  my-3">
                  <div className="text-center">
                      <h2 className="text-primary">Merci encore de votre soutien ! 🗳 </h2>
                      <p>Merci d’avoir porté votre procuration, pour le faire savoir à votre mandant cliquez sur le bouton ci-dessous :</p>
                      {status == "pending" && <a onClick={handleValide} className="btn btn-primary">J'ai porté ma procuration au {tours[tour]}</a>}
                      {status == "success" && <Alert severity="success">Votre mandant est désormais informé(e) !</Alert>}
                  </div>
              </Container>
          </Container>)
    }
};
