import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import frLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import isValid from 'date-fns/isValid';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import tooltipImage from '../images/carte-electorale-nne.jpeg';

export default function InscriptionForm (props){

    let [user, setUser] = useState({
        familyName:"",
        preferredName:"",
        firstName:"",
        birthDate:"",
        gender:"",
        homeAddress:"",
        zipCode:"",
        city:"",
        email:"",
        phoneNumber:"",
        voteCity:"",
        numElecteur: ""
    });
    let [listCommunes, setListCommunes] = useState([]);

    const CustomTextField = styled(TextField)(({ theme }) => ({
      width: "100%",
      backgroundColor: '#ffffff',
      '& .Mui-error' : {
        '&:hover fieldset' : {
          borderColor: '#d32f2f'
        },
      },
      '.MuiInputLabel-root' : {
        color: '#212529',
        '&.Mui-focused': {
          color: '#212529',
          opacity: 0.65,
          transform: 'translate(15px, 7px) scale(0.75)',
        },
        '&.MuiFormLabel-filled': {
          opacity: 0.65,
          transform: 'translate(15px, 7px) scale(0.75)',
        }
      },
      '.MuiOutlinedInput-input': {
        padding: '26.5px 14px 6.5px 14px',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-error' : {
          color: '#212529',
          '.MuiButtonBase-root': {
            color: '#d32f2f'
          },
          '&:hover fieldset': {
            borderColor: '#d32f2f'
          }
        },
        '&.Mui-error.Mui-focused fieldset': {
          boxShadow: '0 0 0 0.25rem rgb(220 53 69 / 25%)',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)'
        },
        '&.Mui-focused fieldset': {
          boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
          border: '1px solid #104a97',
        },
        '&.Mui-focused': {
          color: '#212529'
        },
        'legend': {
          width: 0
        }
      },
    }));

    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        maxWidth: 350,
      },
    }));

    function handleDatePicker(value) {
      console.log(value)
      if (isValid(value)) {
        let date = format(value, 'yyyy-MM-dd');
        let e = {target: {name: "birthDate", value: date}};
        if (date[0] !== '0') {
          handleInput(e);
        }
      }

    }

    function handleNumElecteur(value) {
      const numElecteur = value.target.value.replace(/\D/g, '');
      handleInput({target: {name: "numElecteur", value: numElecteur}});
    }

    function handleAutocomplete(event, newValue) {
      if (newValue) {
        handleInput({target: {name: "voteCity", value: newValue.label}});
      } else {
        handleInput({target: {name: "voteCity", value: ""}});
      }
    }

    function handleInput(event){
        let newUser = {...user, [event.target.name]:event.target.value.trim()}; /* We are doing a copy of user to change only one element of the object*/
        setUser(newUser); /* We update "user" values by registering the modified copy of user*/
        handleCallBack(newUser)
    }

    function handleCallBack(newUser){
        props.parentCallBack(newUser);
    } // Send user data back to Mandant.js or Mandataires.js

    return (
        <>
        <Container maxWidth="sm">
          <Box
            sx={{
              width: "100%",
              border: '1px solid',
              borderColor: 'medium-blue.main',
              borderRadius: 3,
              p: 3,
              boxShadow: 4,
              backgroundColor: '#eaecf4',
              mb: 2
            }}
          >
            <h5>Votre identité</h5>
            <FloatingLabel
              controlId="familyName"
              label="Nom de naissance *"
              className="mb-2"
            >
              <Form.Control onChange={handleInput} isInvalid={props.formErrors.familyName} name="familyName" type="text" placeholder="DUPONT" required/>
            </FloatingLabel>
            <FloatingLabel
              controlId="preferredName"
              label="Nom d'usage *"
              className="mb-2"
            >
              <Form.Control onChange={handleInput} isInvalid={props.formErrors.preferredName} name="preferredName" type="text" placeholder="DUBOIS" required/>
            </FloatingLabel>
            <FloatingLabel
              controlId="firstName *"
              label="Prénom(s)"
              className="mb-2"
            >
              <Form.Control onChange={handleInput} isInvalid={props.formErrors.firstName} name="firstName" type="text" placeholder="DUBOIS" required/>
              <Form.Text id="firstNameHelpBlock" muted>
              Merci de bien donner <em className="fw-bold">l'ensemble</em> de vos prénoms tels qu'ils sont présentés sur votre acte de naissance
              </Form.Text>
            </FloatingLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                disableFuture
                label="Date de naissance *"
                openTo="year"
                views={['year', 'month', 'day']}
                inputFormat='dd/MM/yyyy'
                name="birthDate"
                value={user.birthDate}
                onChange={handleDatePicker}
                renderInput={(params) => <CustomTextField {...params} error={props.formErrors.birthDate} />}
              />
            </LocalizationProvider>
            <FormControl className="has-validation my-2">
              <RadioGroup
                row
                name="gender"
                onChange={handleInput}
                className={props.formErrors.gender ? "is-invalid" : ""}
              >
                <FormControlLabel value="femme" control={<Radio color="medium-blue" size="small" />} label="Femme" />
                <FormControlLabel value="homme" control={<Radio color="medium-blue" size="small" />} label="Homme" />
              </RadioGroup>
              <Form.Text id="firstNameHelpBlock" className="invalid-feedback mt-0 mb-4">
                Champ obligatoire
              </Form.Text>
            </FormControl>
            <FormControl>
              <FloatingLabel
                controlId="numElecteur"
                label="Numéro national d'electeur"
                className="mb-2"
              >
                <Form.Control onChange={handleNumElecteur} isInvalid={props.formErrors.numElecteur} name="numElecteur" type="text" value={user.numElecteur} placeholder="123456789"/>
                <Form.Text id="firstNameHelpBlock" muted>
                Numéro national d’électeur à 8 ou 9 chiffres figurant sur votre carte électorale ou consultable via <a className="text-primary fst-italic" href="https://www.service-public.fr/particuliers/vosdroits/services-en-ligne-et-formulaires/ISE" target="_blank">Service-Public.fr</a>
                </Form.Text>
              </FloatingLabel>

              <HtmlTooltip
                title={
                  <React.Fragment>
                    <img
                      src={tooltipImage}
                      width={300}
                    />
                  </React.Fragment>
                }
              >
                <InfoOutlinedIcon sx={{
                  'position': 'absolute',
                  'right': '10px',
                  'top': '16px',
                }}/>
              </HtmlTooltip>

            </FormControl>
          </Box>

          <Box
            sx={{
              width: "100%",
              border: '1px solid',
              borderColor: 'medium-blue.main',
              borderRadius: 3,
              p: 3,
              boxShadow: 4,
              backgroundColor: '#eaecf4',
            }}
          >
            <h5>Vos informations de contact</h5>
            <Row className="mb-2">
              <Form.Group as={Col}>
                <FloatingLabel
                  controlId="homeAddress"
                  label="Adresse *"
                  className="mb-2"
                >
                  <Form.Control onChange={handleInput} isInvalid={props.formErrors.homeAddress} name="homeAddress" type="text" placeholder="Adresse" required/>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col}>
                <FloatingLabel
                  controlId="zipCode"
                  label="Code postal *"
                  className="mb-2"
                >
                  <Form.Control onChange={handleInput} isInvalid={props.formErrors.zipCode} name="zipCode" type="text" placeholder="37000" required/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel
                  controlId="city"
                  label="Commune *"
                  className="mb-2"
                >
                  <Form.Control onChange={handleInput} isInvalid={props.formErrors.city} name="city" type="text" placeholder="Ville" required/>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col}>
                <FloatingLabel
                  controlId="email"
                  label="Email *"
                  className="mb-2"
                >
                  <Form.Control onChange={handleInput} isInvalid={props.formErrors.email} name="email" type="email" placeholder="julie.dupont@gmail.com" required/>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col}>
                <FloatingLabel
                  controlId="phoneNumber"
                  label="Numéro de téléphone *"
                  className="mb-2"
                >
                  <Form.Control onChange={handleInput} isInvalid={props.formErrors.phoneNumber} name="phoneNumber" type="tel" minLength={10} placeholder="0123456789" required/>
                </FloatingLabel>
              </Form.Group>
            </Row>

          </Box>

          <h3 className="my-3" style={{ color: props.fontColor }} >Et pour mon vote ? *</h3>
          <Row>
            <Autocomplete
              id="voteCity"
              options={props.communes}
              onChange={handleAutocomplete}
              sx={{
                width: '100%',
                'fieldset' : {
                  borderColor: props.formErrors.voteCity ? '#d32f2f' : '#ced4da',
                },
                '.MuiAutocomplete-inputRoot:hover fieldset': {
                  borderColor: props.formErrors.voteCity ? '#d32f2f' : '#ced4da',
                },
              }}
              renderInput={(params) => <TextField {...params} color={props.formErrors.voteCity ? "medium-red" : "medium-blue"} name="voteCity" label="Je vote dans la commune de..." />}
            />

          </Row>


        </Container>
        </>
    )
}
