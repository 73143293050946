import React, {useEffect, useState} from 'react';
import '../css/home.css';
import logo from '../images/Logo-Eaggle-fond-outline.png';
import { Link, useParams, Navigate } from "react-router-dom";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

export default function Home () {

  let params = useParams();
  let [isLoading, setIsLoading] = useState(true);
  let [election, setElection] = useState({});
  let [procurationOptions, setProcurationOptions] = useState({});
  let [notFound, setNotFound] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ROOT}procuration/public/${params.electionSlug}`)
      .then(function (response) {
        if (response.data) {
          setIsLoading(false);
          setElection(response.data.data);
          setProcurationOptions(JSON.parse(response.data.data.procurationOptions));
        } else {
          setNotFound(true);
        }
      })
      .catch(function (error) {
        setNotFound(true);
      })
  }, []);

    if (isLoading) {
      return ( <>
        {notFound && <Navigate to='/404' />}
        <div className="position-absolute top-50 start-50 translate-middle">
          <CircularProgress />
        </div>
      </>);
    } else {
      return (
          <>
              <header style={{
                backgroundColor: procurationOptions.backgroundColor || '#104a97',
                backgroundImage: `url(${process.env.REACT_APP_API_ROOT}public/header/${procurationOptions.header || 'default.jpg'}`
              }}></header>
              <section className="action-list">
                  <div className="procuration-actions">
                      <div className="action-item text-center">
                          <Link to='mandant' className="link">Je veux donner procuration</Link>
                      </div>
                      <div className="action-item text-center">
                          <Link to='mandataire' className="link">Je peux porter une procuration</Link>
                      </div>
                  </div>

              </section>
              <footer className="fixed-bottom" style={{
                backgroundColor: procurationOptions.footerColor || '#104a97',
                color: procurationOptions.fontColor || '#ffffff'
              }}>
                  <ul className="legal">
                      <li>Mentions légales</li>
                      <li>Protection des données</li>
                  </ul>
              </footer>
          </>
      );
    }
};
